module.exports = [{
      plugin: require('/home/travis/build/dsvensson/dsvensson.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/dsvensson/dsvensson.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/dsvensson/dsvensson.github.io/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/typography","omitGoogleFont":true},
    },{
      plugin: require('/home/travis/build/dsvensson/dsvensson.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
